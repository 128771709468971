<template>
  <div class="the-heading">
    <router-link :to="$store.getters.clientType === 'web' ? {name: 'home'} : {name: 'wap.menus'}" tag="a" class="the-heading-left">
      <!-- <embed src="/static/logo/eloop-white.gif" v-if="theme === 'white'" height="70%" type="image/svg+xml"/> -->
      <!-- <img src="/static/logo/eloop-white.gif" v-if="theme === 'white'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/eloop-dark-new.gif" v-else-if="theme === 'dark'" height="100%" type="image/svg+xml"/>
      <img src="/static/logo/eloop.gif" v-else height="100%" type="image/svg+xml"/> -->
      <img src="/static/logo/blue-logo.png" v-if="theme === 'blue'" height="50%" style="margin-left: 20px;"/>
      <img src="/static/logo/pink-logo.png" v-else height="50%" style="margin-left: 20px;"/>
    </router-link>
    <!-- warp利用position: relative使得hover展开的菜单刚好填充满右侧 -->
    <div class="menu-child-warp">
      <div class="the-heading-center">
        <slot></slot>
      </div>
      <div class="the-heading-right">
        <the-user></the-user>
        <theme-switch></theme-switch>
      </div>
    </div>
  </div>
</template>

<script>
import TheUser from './TheUser'
import ThemeSwitch from './ThemeSwitch'

export default {
  name: 'TheHeading',
  components: {
    TheUser,
    ThemeSwitch
  },
  props: {
    noMenu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    theme () {
      return this.$store.getters.theme
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
@import '../../styles/theme.less';
.the-heading {
  height: @the-heading-height;
  min-height: @the-heading-height;
  display: flex;
  z-index: 30;
}
.the-heading-left {
  height: 100%;
  display: flex;
  align-items: center;
  // padding-right: 2rem;
  // .logo {
  //   display: block;
  //   width: 91px;
  //   height: 38px;
  //   background-repeat: no-repeat;
  //   background-position: center center;
  //   background-size: 100%;
  // }
}
.the-heading-center {
  flex: 1;
  height: 100%;
}
.the-heading-right {
  height: 100%;
  display: flex;
  padding-right: 15px;
  align-items: center;
}

.menu-child-warp {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

// 主题
// .default-theme .logo {
//   background-image: url(/static/logo/eloop.svg);
// }
// .white-theme .logo {
//   background-image: url(/static/logo/eloop-white.svg);
// }
// .dark-theme .logo {
//   background-image: url(/static/logo/eloop-dark.svg);
// }

.blue-theme {
  .the-heading {
    background-image: url('/static/blue-theme/images/top.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center right;
    background-color: #FFF;
  }
}
</style>
