<template>
  <div class="theme-switch" @mouseenter="themeSwitch = true" @mouseleave="themeSwitch = false">
    <i class="iconfont icon-yifu"></i>
    <transition name="slide-fade">
      <div class="theme-list" v-show="themeSwitch">
          <template v-for="theme in $store.getters.themeList">
            <div class="theme" :class="{active: $store.getters.theme === theme.key}" v-show="theme.status === 1" :style="{backgroundColor: theme.color}" :key="theme.key" @click="switchTheme(theme)"></div>
          </template>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      themeSwitch: false
    };
  },
  props: {},
  computed: {},
  methods: {
    switchTheme (theme) {
      this.$store.dispatch('switchTheme', theme.key)
      if (this.$store.getters.clientType === 'wap') {
        this.themeSwitch = false
      }
    }
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  errorCaptured() {}
}
</script>

<style lang="less" scoped>
@import '../../styles/theme.less';
@import '../../styles/values.less';
.theme-list {
  // background-color: #FFF;
  background-color: #f8f8f9;
  // box-shadow: 0 1px 6px rgb(95, 99, 103);
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  // top: @the-heading-height;
  top: 100%;
  right: 0;
  padding: 10px;
  .theme {
    transition: all .3s;
    flex: 1;
    border: 1px solid #EEE;
    border-radius: 10px;
    width: 20px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    height: 20px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 1px 6px rgb(87, 163, 243);
    }
  }
}
.theme-switch {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  z-index: 9;
}
.icon-yifu {
  cursor: pointer;
  font-size: 1.5rem;
}
.blue-theme {
  .theme.active {
    border-color: #FFF;
    transform: scale(1.3, 1.3);
  }
  .icon-yifu {
    color: #FFF;
  }
}
.slide-fade-enter-active {
  transition: all .3s linear;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>